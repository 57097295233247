*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  // overflow-x: hidden;
  background-color: #F7F9FD;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 1px;
  height: 0px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.App {
  position: relative;
  line-height: 1.5;
  // overflow-x: hidden;
}

.ar {
  direction: rtl !important;
  font-family: 'Noto Kufi Arabic', sans-serif;
}

.en , .fr {
  direction: ltr !important;
  font-family: 'Cairo', sans-serif;
}

[dir="ltr"] {
  font-family: 'Cairo', sans-serif;
}

[dir="rtl"] {
  font-family: 'Noto Kufi Arabic', sans-serif;
}

// .ar {
//   direction: rtl;
//   font-family: 'Noto Kufi Arabic', sans-serif;
// }
// .en {
//   direction: ltr;
//   font-family: 'Cairo', sans-serif;
// }
.goToUp {
  display: none;
  width: 40px;
  height: 40px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  border-radius: 50%;
  background-color: #245c73;
  color: white;
  border: 0;
  outline: none;
  z-index: 10;
  font-size: 20px;
  cursor: pointer;
  transition: 0.3s;
  align-items: center;
  justify-content: center;

  &:hover {
    transform: translateY(-5px);
  }
}